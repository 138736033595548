import React, { useEffect, useState } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from './Firebase';
import logo from './assets/StarTraceLogo_white.svg';
import './Header.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { getAuth, onAuthStateChanged, signOut } from 'firebase/auth';

function Header() {
    const location = useLocation();
    const currentPath = location.pathname.toLowerCase();

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const auth = getAuth();
    const [activeUsers, setActiveUsers] = useState(0);
    const [scoutUploads, setScoutUploads] = useState(0);

    const navigate = useNavigate();
    const goToLogin = () => navigate('/login');
    const goToSignup = () => navigate('/signup');
    const goToHome = () => navigate('/home');
    const goToHealth = () => navigate('/health'); // Updated route

    const isAuthPage = currentPath === '/login' || currentPath === '/signup';
    const isProfilePage = currentPath === '/profile';

    useEffect(() => {
        fetchActiveUsersAndScouts();
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            setIsLoggedIn(!!user);
        });

        return () => unsubscribe();
    }, [auth]);

    const handleLogout = () => {
        signOut(auth).then(() => {
            navigate('/home');
        }).catch((error) => {
            console.error('Logout error', error);
        });
    };

    const navigateHome = () => {
        navigate('/home');
    };

    const fetchActiveUsersAndScouts = async () => {
        const usersSnapshot = await getDocs(collection(db, "users"));
        setActiveUsers(usersSnapshot.size);

        const starscoutDataSnapshot = await getDocs(collection(db, `starscoutData`));
        setScoutUploads(starscoutDataSnapshot.size)
    };

    return (
        <div className="header">
            <img src={logo} alt="StarTrace Logo" onClick={navigateHome} className="header-logo" />

            {isLoggedIn && isProfilePage ? (
                <div className="header-buttons">
                    <button className="auth-button" onClick={goToHome}>View Scope</button>
                    <button className="auth-button" onClick={handleLogout}>Log Out</button>
                </div>
            ) : !isAuthPage && (
                <>
                    <div className="active-info">
                        <p>Active Users: {activeUsers}</p>
                        <p>StarScout Uploads: {scoutUploads}</p>
                    </div>
                    <div className="header-buttons">
                        
                        {isLoggedIn ? (
                            <>
                                <button className="auth-button white-button" onClick={goToHealth}>Health</button>
                                <button className="auth-button" onClick={() => navigate('/profile')}>Profile</button>
                                <button className="auth-button" onClick={handleLogout}>Log Out</button>
                            </>
                        ) : (
                            <>
                                <button className="sign-in-btn" onClick={goToLogin}>Login</button>
                                <button className="sign-up-btn" onClick={goToSignup}>Sign Up</button>
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    );
}

export default Header;
