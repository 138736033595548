import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getAuth, deleteUser, onAuthStateChanged } from 'firebase/auth';
import { db } from './Firebase';
import { doc, getDoc, collection, query, where, getDocs, updateDoc, arrayUnion, arrayRemove } from 'firebase/firestore';
import Header from './Header';
import './Profile.css';
import starBackground from './assets/starBackground.png';

function Profile() {
    const [deviceID, setDeviceID] = useState('');
    const [userDetails, setUserDetails] = useState({ username: '', createdAt: '', scoutIDs: [] });
    const [activeScouts, setActiveScouts] = useState([]); // Correctly reintroduced activeScouts
    const auth = getAuth();
    const user = auth.currentUser;
    const navigate = useNavigate();

    useEffect(() => {
        document.body.style.backgroundImage = `url(${starBackground})`;
        document.body.style.backgroundSize = 'cover';
        document.body.style.backgroundPosition = 'center';

        return () => {
            document.body.style.backgroundImage = '';
            document.body.style.backgroundSize = '';
            document.body.style.backgroundPosition = '';
        };
    }, []);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (!user) {
                navigate('/');
            }
        });

        return () => unsubscribe();
    }, [navigate, auth]);

    useEffect(() => {
        const fetchUserDetails = async () => {
            if (user) {
                const userRef = doc(db, "users", user.uid);
                const docSnap = await getDoc(userRef);
                if (docSnap.exists()) {
                    setUserDetails({
                        username: docSnap.data().username,
                        createdAt: docSnap.data().createdAt,
                        scoutIDs: docSnap.data().scoutIDs || [],
                    });
                }
            } else {
                navigate('/');
            }
        };

        fetchUserDetails();
    }, [navigate, auth, user]);



    // Fetch Scout Reports based on scoutIDs
    useEffect(() => {
        const fetchScoutReports = async () => {
            if (userDetails.scoutIDs.length > 0) {
                const scouts = await Promise.all(userDetails.scoutIDs.map(async (scoutID) => {
                    const scoutRef = doc(db, "starsage_predictions", scoutID);
                    const docSnap = await getDoc(scoutRef);
                    if (docSnap.exists()) {
                        return { id: scoutID, ...docSnap.data() };
                    }
                    return null;
                }));
                // Filter out any nulls in case some documents didn't exist
                setActiveScouts(scouts.filter(scout => scout !== null));
            } else {
                setActiveScouts([]); // No ScoutIDs, clear any existing scouts
            }
        };

        fetchScoutReports();
    }, [userDetails.scoutIDs]); // Dependency on scoutIDs

    const handleAddDevice = async () => {
        if (user && deviceID) {
            const userRef = doc(db, "users", user.uid);
            await updateDoc(userRef, {
                scoutIDs: arrayUnion(deviceID)
            });
            setDeviceID('');
            setUserDetails(prevDetails => ({
                ...prevDetails,
                scoutIDs: [...prevDetails.scoutIDs, deviceID]
            }));
        }
    };

    const handleDeleteScoutID = async (scoutID) => {
        if (user) {
            const userRef = doc(db, "users", user.uid);
            await updateDoc(userRef, {
                scoutIDs: arrayRemove(scoutID) // Remove the scoutID from the scoutIDs array
            });
            // Update local state to immediately reflect the change
            setUserDetails(prevDetails => ({
                ...prevDetails,
                scoutIDs: prevDetails.scoutIDs.filter(id => id !== scoutID)
            }));
        }
    };

    const handleDeleteAccount = () => { // Correctly reintroduced handleDeleteAccount
        if (window.confirm("Are you sure you want to delete your account?")) {
            deleteUser(user)
                .then(() => {
                    alert("Account deleted successfully.");
                    navigate('/');
                })
                .catch((error) => {
                    console.error('Error deleting account:', error);
                    alert("Failed to delete account.");
                });
        }
    };

    return (
        <>
            <Header />
            <div className="profile-container">
                {/* Account Information Section */}
                <div className="section">
                    <h2>Account Information</h2>
                    <p>Email: {user ? user.email : ''}</p>
                    <p>Username: {userDetails.username}</p>
                    <p>Date Created: {user ? user.metadata.creationTime : ''}</p>
                </div>
                {/* Active Scouts Section */}
                <div className="section">
                <h2>Active Scouts</h2>
                    {userDetails.scoutIDs.length > 0 ? (
                        userDetails.scoutIDs.map((id, index) => (
                            <p key={index}>
                                Scout ID: {id}
                                <span 
                                    onClick={() => handleDeleteScoutID(id)} 
                                    style={{ marginLeft: '10px', cursor: 'pointer', color: 'red' }}>
                                    X
                                </span>
                            </p>
                        ))
                    ) : (
                        <p>No Scout IDs found.</p>
                    )}
                </div>
                {/* Scout Reports Section */}
               
                <div className="section">
                    <h2>Scout Reports</h2>
                    {activeScouts.length > 0 ? (
                        activeScouts.map((scout, index) => (
                            <div key={index}>
                                <p>Scout ID: {scout.id}</p>
                                <p>City: {scout.City}</p>
                                <p>Country: {scout.Country}</p>
                                <p>Download Speed: {scout.DownloadSpeed} Mbps</p>
                                <p>Upload Speed: {scout.UploadSpeed} Mbps</p>
                                <p>QoS Score: {scout.QoSScore}</p>
                                <p>Is Anomalous: {scout.IsAnomalous ? 'Yes' : 'No'}</p>
                            </div>
                        ))
                    ) : (
                        <p>No scout reports found.</p>
                    )}
                </div>
                <div className="section">
                <hr />
                    <div className="add-scout-subsection">
                        <h3>Add New Scout Device</h3>
                        <input 
                            type="text" 
                            value={deviceID} 
                            onChange={(e) => setDeviceID(e.target.value)} 
                            placeholder="Enter Scout DeviceID"
                        />
                        <button onClick={handleAddDevice}>Add</button>
                    </div>
                </div>
                <button onClick={handleDeleteAccount} className="delete-account-btn">Delete Account</button>
            </div>
        </>
    );
}

export default Profile;
