import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import { collection, query, limit, getDocs } from 'firebase/firestore';
import { db } from './Firebase';
import './Home.css';
import Header from './Header';

import 'mapbox-gl/dist/mapbox-gl.css';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_TOKEN;

function Home() {
    const [map, setMap] = useState(null);
    const [viewType, setViewType] = useState('points'); // Initialize view type to 'points'

    useEffect(() => {
        const mapInstance = new mapboxgl.Map({
            container: 'map',
            style: 'mapbox://styles/mapbox/satellite-v9', // Using a light theme for better heatmap visibility
            center: [0, 0],
            zoom: 1
        });

        mapInstance.on('load', async () => {
            const data = await fetchStarlinkData();
            const bounds = new mapboxgl.LngLatBounds(); // Used to auto-zoom the map

            // Create a GeoJSON object for the heatmap
            const geojsonData = {
                type: 'FeatureCollection',
                features: data.map(item => ({
                    type: 'Feature',
                    properties: {
                        ...item,
                        value: (item.DownloadSpeed + item.UploadSpeed) / 2,
                    },
                    geometry: {
                        type: 'Point',
                        coordinates: [item.longitude, item.latitude]
                    }
                }))
            };

            // Add the GeoJSON data as a source for the heatmap
            mapInstance.addSource('speedData', {
                type: 'geojson',
                data: geojsonData
            });

            // Add the heatmap layer
mapInstance.addLayer({
    id: 'speedHeatmap',
    type: 'heatmap',
    source: 'speedData',
    maxzoom: 9,
    paint: {
        // Increase the heatmap weight based on average speed
        'heatmap-weight': [
            'interpolate',
            ['linear'],
            ['get', 'value'],
            0, 0,
            100, 1
        ],
        // Increase the heatmap intensity by zoom level
        'heatmap-intensity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 1,
            9, 3
        ],
        // Color ramp for the heatmap: Begin at 0 and go to a high value to simulate shading
        'heatmap-color': [
            'interpolate',
            ['linear'],
            ['heatmap-density'],
            0, 'rgba(33,102,172,0)',
            0.2, 'blue',
            0.4, 'cyan',
            0.6, 'lime',
            0.8, 'yellow',
            1, 'red'
        ],
        // Adjust the heatmap radius by zoom level for larger points
        'heatmap-radius': [
            'interpolate',
            ['linear'],
            ['zoom'],
            0, 30, // Increase the minimum radius to make points larger at the lowest zoom level
            9, 60  // Increase the maximum radius to make points larger at the highest zoom level before transitioning to circles
        ],
        // Transition from heatmap to circle layer by zoom level
        'heatmap-opacity': [
            'interpolate',
            ['linear'],
            ['zoom'],
            7, 1,
            9, 0
        ],
    }
});


            // Initially set heatmap visibility to none
            mapInstance.setLayoutProperty('speedHeatmap', 'visibility', 'none');

            // Add markers with popups and adjust colors based on IsAnomalous
            data.forEach(item => {
                const popupContent = `
                    <div>
                        <h3>StarSage Prediction</h3>
                        <p><strong>Scout ID:</strong> ${item.ScoutID}</p>
                        <p><strong>Location:</strong> ${item.City}, ${item.Region}, ${item.Country}</p>
                        <p><strong>Download (mbps):</strong> ${item.DownloadSpeed} Mbps</p>
                        <p><strong>Upload (mbps):</strong> ${item.UploadSpeed} Mbps</p>
                        <p><strong>QoS Score:</strong> ${item.QoSScore}</p>
                        <p><strong>Unusual Result (Anomalous): </strong> ${item.IsAnomalous ? 'Yes' : 'No'}</p>
                    </div>
                `;
                const markerColor = item.IsAnomalous ? 'red' : 'green';

                const marker = new mapboxgl.Marker({ color: markerColor })
                    .setLngLat([item.longitude, item.latitude])
                    .setPopup(new mapboxgl.Popup({ offset: 25 }).setHTML(popupContent))
                    .addTo(mapInstance);

                // Extend the bounds to include each marker's position
                bounds.extend([item.longitude, item.latitude]);
            });

            // Auto-zoom the map to fit the markers
            mapInstance.fitBounds(bounds, { padding: 50 });

            setMap(mapInstance);
        });

        mapInstance.addControl(new mapboxgl.NavigationControl());
    }, []);

    async function fetchStarlinkData() {
        const q = query(collection(db, "starsage_predictions"), limit(400));
        const querySnapshot = await getDocs(q);
        const dataArray = [];

        querySnapshot.forEach(doc => {
            const data = doc.data();
            if (data.GeoLocation) {
                dataArray.push({
                    ...data,
                    ScoutID: doc.id,
                    longitude: data.GeoLocation.longitude,
                    latitude: data.GeoLocation.latitude
                });
            }
        });

        return dataArray;
    }

    const toggleView = () => {
        if (!map) return;

        const newViewType = viewType === 'points' ? 'heatmap' : 'points';
        setViewType(newViewType);

        const heatmapVisibility = map.getLayoutProperty('speedHeatmap', 'visibility');

        if (heatmapVisibility === 'visible') {
            map.setLayoutProperty('speedHeatmap', 'visibility', 'none');
        } else {
            map.setLayoutProperty('speedHeatmap', 'visibility', 'visible');
        }
    };

    return (
        <main>
            <Header />
            <div id="map" style={{ width: '100%', height: '100vh' }}></div>
            <button onClick={toggleView} style={{ position: 'absolute', top: '100px', left: '10px', zIndex: 1 }}>
                View Bandwidth Heatmap
            </button>
        </main>
    );
}

export default Home;
