    import React, { useEffect, useState } from 'react';
    import { db } from './Firebase';
    import { collection, query, getDocs } from 'firebase/firestore';
    import './NetworkHealth.css'; // Importing your provided CSS
    import Header from './Header'; // Import your Header component
    import leaderboardBackground from './assets/leaderboardBackground.jpg';

    function NetworkHealth() {
        const [averageSpeed, setAverageSpeed] = useState(null);
        const [lowestSpeedAreas, setLowestSpeedAreas] = useState([]);
        const [highestSpeedState, setHighestSpeedState] = useState(null);

        useEffect(() => {
            fetchNetworkData();
        }, []);

        async function fetchNetworkData() {
            const speedDataQuery = query(collection(db, "starsage_predictions"));
            const querySnapshot = await getDocs(speedDataQuery);
            const speeds = [];
    
            querySnapshot.forEach(doc => {
                const data = doc.data();
                if (data.DownloadSpeed && data.UploadSpeed) {
                    const avgSpeed = (data.DownloadSpeed + data.UploadSpeed) / 2;
                    speeds.push({
                        name: `${data.City}, ${data.State}`, // Adjust based on your data structure
                        speed: avgSpeed
                    });
                }
            });
    
            if (speeds.length > 0) {
                // Calculate average speed
                const totalSpeed = speeds.reduce((acc, curr) => acc + curr.speed, 0);
                setAverageSpeed((totalSpeed / speeds.length).toFixed(2)); // Ensure average speed is to 2 decimal places
    
                // Find 5 lowest speed areas
                const sortedSpeeds = speeds.sort((a, b) => a.speed - b.speed);
                setLowestSpeedAreas(sortedSpeeds.slice(0, 5));
    
                // Find the highest speed state
                const highestSpeed = sortedSpeeds[sortedSpeeds.length - 1];
                setHighestSpeedState(highestSpeed);
            }
        }



        useEffect(() => {
            // Apply background image to the body when the component mounts
            document.body.style.backgroundImage = `url(${leaderboardBackground})`;
            document.body.style.backgroundSize = 'cover';
            document.body.style.backgroundPosition = 'center';
            document.body.style.margin = '0'; // Remove default margin
            document.body.style.height = '100%'; // Ensure height covers full page height
            document.body.style.backgroundAttachment = 'fixed'; // Optional: Fixes background during scroll

            // Cleanup function to reset the body's background when the component unmounts
            return () => {
                document.body.style.backgroundImage = '';
                document.body.style.backgroundSize = '';
                document.body.style.backgroundPosition = '';
                document.body.style.margin = '';
                document.body.style.height = '';
                document.body.style.backgroundAttachment = '';
            };
        }, []);
        
        return (
            <div>
                <Header />
        
            <div className="leaderboard-flex-container">
                
                <div className="leaderboard-container">
                    <h1 className="leaderboard-title">Network Health</h1>
                    <div>
                        <h2>Average Network Speed</h2>
                        <p>{averageSpeed ? `${averageSpeed} Mbps` : 'Loading...'}</p>
                    </div>
                    <div>
                        <h2>5 Lowest Speed Areas in the United States</h2>
                        <ul className="leaderboard-list">
                            {lowestSpeedAreas.length > 0 ? (
                                lowestSpeedAreas.map((area, index) => (
                                    <li key={index} className="leaderboard-item">
                                        
                                        <strong>{area.name}</strong> - <span>{area.speed.toFixed(2)} Mbps</span>
                                    </li>
                                ))
                            ) : (
                                <p>Loading...</p>
                            )}
                        </ul>
                    </div>
                    <div>
                        <h2>Highest Speed State in the United States</h2>
                        <p className="leaderboard-item">
                            {highestSpeedState ? (
                                <>
                                    <strong>{highestSpeedState.name}</strong> - <span>{highestSpeedState.speed} Mbps</span>
                                </>
                            ) : 'Loading...'}
                        </p>
                    </div>
                </div>
            </div>
            </div>
        );
    }

    export default NetworkHealth;

